<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Tambah Progress" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full mt-1">
            <label class="ml-1 text-xs">Tanggal</label>
            <flat-pickr class="w-full" v-model="data.tgl"></flat-pickr>
          </div>
          <div class="vx-col sm:w-3/12 w-full mt-1">
            <label class="ml-1 text-xs">Bobot %</label>
            <vs-input class="w-full" type="number" v-model="itemSpkPekerjaan.bobot" disabled/>
          </div>
          <div class="vx-col sm:w-3/12 w-full mt-1">
            <label class="ml-1 text-xs">Progress %</label>
            <vs-input class="w-full" type="number" v-model="data.progress" :danger="data.progress > 100" danger-text="Maksimal 100%"/>
          </div>
          <div class="vx-col sm:w-3/12 w-full mt-1">
            <label class="ml-1 text-xs">Status *</label>
            <vs-select class="w-full" v-model="data.status_kontraktor" disabled>
              <vs-select-item v-for="(it, index) in ['PROSES', 'SELESAI']" :key="index" :value="it" :text="it"/>
            </vs-select>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/1 w-full">
            <label class="ml-1 text-xs">Pengerjaan *</label>
            <vs-input class="w-full" v-model="data.pekerjaan"/>
          </div>
        </div>
        <div class="vx-row mb-1">
          <div class="vx-col sm:w-1/1 w-full">
            <label class="ml-1 text-xs">Keterangan *</label>
            <vs-textarea class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.keterangan_kontraktor"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Foto * <span class="text-xs opacity-75" v-if="isCompressingFoto">(compressing...)</span></label>
            <vs-input class="w-full" type="file" accept="image/*" multiple v-model="data.fotoTemp" @change="onSelectedPhotos"/>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">File</label>
            <vs-input class="w-full" type="file" accept=".jpeg,.jpg,.png,.pdf" multiple v-model="data.filesTemp" @change="onSelectedFiles"/>
          </div>
        </div>

        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading || isCompressingFoto" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

    </vs-popup>
  </div>
</template>

<script>
import ProgressUnitRepository from '@/repositories/proyek/progress-unit-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
import moment from 'moment'
import { compressImage, convertToFormData } from '@/utilities/common/global-methods'

export default {
  name: 'ProgressUnitAdd',
  props: ['isActive', 'itemSpkPekerjaan'],
  components: {
    ValidationErrors,
    flatPickr
  },
  data () {
    return {
      isLoading: false,
      isCompressingFoto: false,
      errors: null,
      data: {
        tgl: moment().format('YYYY-MM-DD'),
        status_kontraktor: '',
        foto: [],
        files: []
      }
    }
  },
  watch: {
    'data.progress' (newVal) {
      if (isNaN(newVal)) this.data.status_kontraktor = ''
      this.data.status_kontraktor = newVal < 100 ? 'PROSES' : 'SELESAI'
    }
  },
  methods: {
    async onSelectedPhotos (event) {
      const files = event.target.files
      const items = []
      this.isCompressingFoto = true
      for (const file of files) {
        const compressed = await compressImage(file)
        items.push(compressed)
      }
      this.isCompressingFoto = false
      this.data.foto = items
    },

    onSelectedFiles (event) {
      const files = event.target.files
      const items = []
      for (const file of files) {
        items.push(file)
      }
      this.data.files = items
    },

    save () {
      this.errors = null
      this.isLoading = true

      // params
      const excludeKeys = ['fotoTemp', 'filesTemp']
      const data = _.omit(this.data, excludeKeys)
      data.id_spk_pekerjaan = this.$route.params.idSpkPekerjaan
      data.bobot = this.itemSpkPekerjaan.bobot
      const params = convertToFormData(data)

      ProgressUnitRepository.create(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['tgl'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
